import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_ORDERED_ORDERS_REQUEST } from "constants/OrderStatus/OrderedOrders";
import { fetchOrderedOrdersFailure, fetchOrderedOrdersSuccess } from "redux/actions";
import OrderedOrders from "services/OrderStatus/OrderedOrders";

export function* onFetchOrderedOrders() {
    yield takeLatest(FETCH_ORDERED_ORDERS_REQUEST, function* () {
        try {
            const result = yield call(OrderedOrders.getOrderedOrders);

            if (result.status) {
                yield put(fetchOrderedOrdersSuccess(result.data));
            } else {
                yield put(fetchOrderedOrdersFailure(result.message));
            }
        } catch (error) {
            yield put(fetchOrderedOrdersFailure(error.message));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(onFetchOrderedOrders)
    ]);
}
