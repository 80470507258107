import fetch from "auth/FetchInterceptor";

const RedrawSamples = {};

RedrawSamples.getRedrawSamples = function () {
    return fetch({
        url: `/reports/redraw-samples`,
        method: "get",
    });
};

export default RedrawSamples;
