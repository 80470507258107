import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
  ADMIN_PREFIX_PATH,
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  DOCTOR_PREFIX_PATH,
  QC_PREFIX_PATH,
} from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import AdminLayout from "layouts/admin-layout";
import { CURRENT_USER, LOGIN_EXPIRATION_TIME, PREVIOUSLY_LOGGED_IN } from "redux/constants/Auth";
import { QualityControlLayout } from "layouts/quality-control-layout";
import PageNotFound from "./PageNotFound";
import { ROLE_SUPER_ADMIN, ROLE_GUEST, ROLE_QUALITY_CONTROL } from "configs/AppConfig";
import DoctorLayout from "layouts/doctor-layout";
import { doesThisUserIsDoctor } from "utils/CurrentUserUtil";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  //console.log(isAuthenticated);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        // && new Date(JSON.parse(localStorage.getItem(LOGIN_EXPIRATION_TIME))?.token_expiration_time).getTime() > new Date().getTime() && JSON.parse(localStorage.getItem(LOGIN_EXPIRATION_TIME)) !== null

        isAuthenticated && new Date(JSON.parse(localStorage.getItem(LOGIN_EXPIRATION_TIME))?.token_expiration_time).getTime() > new Date().getTime() && JSON.parse(localStorage.getItem(LOGIN_EXPIRATION_TIME)) !== null
          ? (children) :
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  let previouslyLoggedIn = localStorage.getItem(PREVIOUSLY_LOGGED_IN);
  const role = currentUser?.role;

  let NAV_PATH = role === ROLE_SUPER_ADMIN ? ADMIN_PREFIX_PATH : APP_PREFIX_PATH; // this is let 
  if (role !== ROLE_QUALITY_CONTROL && role !== ROLE_GUEST && !doesThisUserIsDoctor()) {
    if(previouslyLoggedIn?.toString() === "1"){
      NAV_PATH = ADMIN_PREFIX_PATH
    } else {
      NAV_PATH = ADMIN_PREFIX_PATH + "/reset/";
    }
  }
  else if (doesThisUserIsDoctor()){ // add a condition if the user doctor is verfied 
    if(previouslyLoggedIn?.toString() === "1"){
      NAV_PATH = DOCTOR_PREFIX_PATH;
    } else {
      NAV_PATH = ADMIN_PREFIX_PATH + "/reset/";
    }
  }
  else if (role === ROLE_QUALITY_CONTROL) {
    NAV_PATH = QC_PREFIX_PATH;
  } else {
    NAV_PATH = APP_PREFIX_PATH;
  }

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>

          <Route exact path="/">
            <Redirect to={NAV_PATH} />
          </Route>

          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>

          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>

          <RouteInterceptor exact path={ADMIN_PREFIX_PATH + "/reset"} isAuthenticated={token}>
            <AdminLayout
              navType="SIDE"
              direction={direction}
            // location={location}
            />
          </RouteInterceptor>

          {/* 
                please do not exchange the code above and below
            */}

          <RouteInterceptor path={ADMIN_PREFIX_PATH} isAuthenticated={token}>
            <AdminLayout
              navType="SIDE"
              direction={direction}
            // location={location}
            />
          </RouteInterceptor>

          <RouteInterceptor path={QC_PREFIX_PATH} isAuthenticated={token}>
            <QualityControlLayout
              navType="SIDE"
              direction={direction}
              location={location}
            />
          </RouteInterceptor>
          <RouteInterceptor path={DOCTOR_PREFIX_PATH} isAuthenticated={token}>
            <DoctorLayout
              navType="SIDE"
              direction={direction}
              location={location}
            />
          </RouteInterceptor>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, direction, token };
};

export default withRouter(connect(mapStateToProps)(Views));
