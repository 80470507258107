import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import moment from 'moment';
import { FETCH_CAHSHIER_DASHBOARD_REPORTS_REQUEST } from "constants/AdminDashboardReports/DashboardReport";
import {
  fetchAdminReportsFailure,
  fetchCashierReportsSuccess,
} from "redux/actions/AdminDashboardReports/DashboardReport";
import DashboardReport from "services/AdminDashboardReports/DashboardReport";
import { fetchDashboardFailure, fetchDashboardSuccess } from "redux/actions";
import { FETCH_DASHBOARD_REQUEST } from "constants/ClientCompany";

export function* onFetchAdminDashboardReports() {
  yield takeLatest(FETCH_DASHBOARD_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(DashboardReport.getDashboard, payload);
      if (result.status) {
        yield put(fetchDashboardSuccess(result.data));
      } else {
        yield put(fetchDashboardFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDashboardFailure(error.message));
    }
  });
}

export function* onFetchCashierDashboard() {
  yield takeLatest(FETCH_CAHSHIER_DASHBOARD_REPORTS_REQUEST, function* () {
    try {
      const result = yield call(DashboardReport.getCashierDashboard);
      if (result.status) {
        yield put(fetchCashierReportsSuccess(result.data));
      } else {
        yield put(fetchAdminReportsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchAdminReportsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchAdminDashboardReports),
    fork(onFetchCashierDashboard),
  ]);
}

